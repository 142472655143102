@use "sass:math";

@import "../required";

.banner-ratio {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;

    &::before {
        display: block;
        content: "";
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        transform: translate(-50%, -50%);
    }
}

.banner-ratio > .banner-ratio-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-ratio-main {
    &::before {
        padding-top: math.percentage(calc(57 / 200));
    }
}

.banner-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.banner-countdown {
    position: absolute;
    top: 50%;
    padding: 5px;
    background-color: $white-80;
    transform: translateY(-50%);

    &.left {
        left: 10px;
    }
    &.center {
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &.right {
        right: 10px;
    }

    @include media-breakpoint-up(md) {
        &.left {
            left: auto;
        }
        &.center {
            left: auto;
            transform: translate(0, -50%);
        }
        &.right {
            right: auto;
        }

        &.left-md {
            left: 10px;
        }
        &.center-md {
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.right-md {
            right: 10px;
        }
    }
}
